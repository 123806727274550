import {ref, reactive, toRefs, onBeforeMount, defineComponent, getCurrentInstance} from 'vue';
import language from './unlockLanguage'
export default defineComponent({
    name: "unlockList",
    setup(){
        let {proxy}=getCurrentInstance() as any;
        const utils=proxy.utils;

        let dataObj=reactive({
            pageListRef:null as any,
            pageList: {
                queryParam: {
                    type: "unlock"
                },
                canPage: false,
                modelMethod: utils.Api.buildUrl("/unlock/pageData")
            }
        })
        onBeforeMount(()=>{
            proxy.addLangProps(language);
        })
        const unlockHandler=async()=>{
            let selectRows = dataObj.pageListRef.getTbInst().getSelection();
            if (selectRows.length == 0) {
                utils.Tools.info({message: proxy.$t('unlock.selTip')});
                return;
            }
            selectRows = selectRows.map((item:any)=>{return item.F_ID;});
            const loading = proxy.$loading({lock: true,text: proxy.$t('loadMsg'),spinner: 'el-icon-loading',background: 'rgba(0, 0, 0, 0.7)'});
            let res = await utils.Api.unlockUser({ids: selectRows});
            if(res.result){
                utils.Tools.success();
                dataObj.pageListRef.queryHandler(true);
            }
            loading.close();
        }
        return{
            ...toRefs(dataObj),unlockHandler
        }
    }
});